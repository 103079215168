.tooltip {
  color: white;
  display: initial;
  padding: 10px;
  border-radius: 3px;
  width: 20%;
  font-size: 12px;
  font-style: italic;
  position: absolute;
  margin: 0 0 0 10px;
  background-color: hsla(0, 0%, 20%, 0.9);
  z-index: 999;
}
.tooltipTick:after {
  position: absolute;
  left: -7px;
  width: 0;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0;
  transform: rotate(90deg);
  top: 20px;
}

.formLabel {
  display: 'block';
  position: 'absolute';
  top: '-10px';
  background-color: 'white';
  font-size: '11px';
  color: '#313131';
  padding: '0px 5px';
  left: '10px';
}
.formDiv {
  position: 'relative';
  min-height: '35px';
}
.checkBoxPlace {
  position: 'absolute';
  top: '5px';
}

.MuiCheckbox-root + .MuiFormControlLabel-label {
  padding-top: 10px;
}

.disabledInput .MuiInput-root {
  background: #e6e6e6 !important;
}

.errorMessageIn input {
  color: #b00020 !important;
  text-transform: initial !important;
  opacity: 1 !important;
}

.MuiAutocomplete-root
  .MuiFormControl-root
  .MuiInput-root:hover:not(.Mui-disabled):before {
  border: none !important;
}

.MuiAutocomplete-root .MuiInputLabel-root {
  position: absolute !important;
  top: -28px !important;
  background: white !important;
  font-size: 0.75rem !important;
  padding: 0px 5px;
  transform: translate(-4px, 27px) scale(1) !important;
}
.MuiAutocomplete-inputRoot {
  background: white !important;
  padding-top: 0px !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.MuiAutocomplete-listbox {
  font-size: 14px !important;
}
.MuiAutocomplete-popper {
  z-index: 999999 !important;
}
.MuiAutocomplete-option {
  background-color: #eee;
  font-family: 'Google-Sans' !important;
}
.MuiAutocomplete-option:hover {
  background-color: #fdd7c1 !important;
}
.MuiAutocomplete-popper {
  width: 250 !important;
}
