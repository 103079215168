.MuiAccordion-root {
  border-radius: 10px !important;
}
.MuiAccordionSummary-root {
  height: 35px !important;
  min-height: 35px !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border-radius: 10px !important;
}
.MuiAccordionSummary-content .MuiTypography-root {
  color: #e92220 !important;
  font-family: "Google-Sans-Bold" !important;
}
