.underLineRoot .heading {
  font-size: 18px;
  font-family: "Google-Sans-Bold";
}
.underLineRoot ::after {
  width: 50px;
  content: "";
  border-bottom: 2px solid #e92220;
  display: block;
  padding-bottom: 5px;
}
