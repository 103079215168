body {
  background-color: #f9f9f9 !important;
  margin: 0;
  padding: 0;
  font-family: "Google-Sans" !important;
}
.root {
  background: #f9f9f9;
  font-family: "Google-Sans" !important;
}
.MuiButton-root {
  font-family: "Google-Sans" !important;
}
.loginSideImage {
  width: 100%;
  height: 100vh;
}
.loginText1 {
  line-height: 1.5;
  display: block;
  font-family: "Google-Sans-Bold";
  font-size: 1.5rem;
  margin-left: 5px;
  text-align: center;
}
.loginText2 {
  display: block;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(99, 115, 129);
  margin-left: 5px;
  text-align: center;
}
.loginText3 {
  padding-top: 20px;
  color: rgb(99, 115, 129);
  font-family: "Google-Sans-Light";
}
.loginBox {
  width: 49%;
  max-width: 1000px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin-left: 5%;
}
.loginLogo {
  width: 70%;
  margin-top: 15px;
}
.mashreqNewLogo {
  width: 27%;
  margin-top: 12px;
}
.MuiSnackbarContent-message {
  font-family: "Google-Sans-Bold";
}
.paperFullWidth {
  width: 100%;
  padding: 10px;
  border-radius: 10px !important;
}
.themeIcon {
  vertical-align: middle;
  fill: #e92220;
  cursor: pointer;
}
.themeIcon svg {
  vertical-align: middle;
  fill: #e92220;
  cursor: pointer;
}
.themeSecondaryIcon {
  vertical-align: middle;
  fill: #19419a;
  cursor: pointer;
}
.themeButtonIcon {
  fill: #fff;
  margin-right: 10px;
}
.editIcon {
  font-size: 1rem;
  fill: #e92220;
  cursor: pointer;
  vertical-align: middle;
}
.linkUI {
  fill: #a9212d;
  color: #a9212d;
  font-family: "Google-Sans-Black-Bold";
  cursor: pointer;
}
.headerAppInfo {
  display: flex;
  flex: auto;
  font-family: "Google-Sans-Black-Bold";
  font-size: clamp(1rem, 1.2rem, 2.5rem) !important;
  padding-top: 7px;
}

.readOnlyField_Box {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  position: relative;
}
.paperTop {
  width: 100%;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.paperBottom {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.MuiTabs-flexContainer .Mui-selected {
  color: #e92220 !important;
  font-family: "Google-Sans-Bold" !important;
}
.MuiTabs-indicator {
  background-color: #e92220 !important;
}
.MuiTab-root {
  font-family: "Google-Sans" !important;
  font-size: 0.8rem !important;
}
.actionButtonStyle {
  padding-top: 10px;
  text-align: right;
}
.tabIcon {
  vertical-align: middle;
  margin-bottom: 0 !important;
  margin-right: 5px;
}
.cardBox {
  padding: 10px;
  cursor: pointer;
}

.centerDiv {
  padding: 15px;
  position: relative;
  top: 50%;
  left: 50%;
  text-align: center;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.priority_high {
  background-color: #f65b5d;
  color: white;
  width: fit-content;
  text-align: center;
  border-radius: 10px;
  padding: 2px 7px;
}
.priority_normal {
  background-color: #ffa30f;
  color: white;
  width: fit-content;
  text-align: center;
  border-radius: 10px;
  padding: 2px 7px;
}
.priority_low {
  background-color: #00bb3f;
  color: white;
  width: fit-content;
  text-align: center;
  border-radius: 10px;
  padding: 2px 7px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.dropzone-container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 5px;
  margin-top: -4px;
  margin-left: 3px;
}

#mutiple-select-label {
  margin: 0px;
  margin-top: 13px;
  border-radius: 5px;
  padding: 0px 5px;
  color: #313131;
  font-weight: 500;
}

#pac-input {
  max-width: 300px !important;
  border-radius: 5px;
  margin-top: 10px;
  border: #e8e8e8;
  height: 25px;
}
#pac-input:focus-visible {
  outline-color: #bbb;
}

.viewImg {
  border: 2px solid #fff;

  box-shadow: 10px 10px 5px #ccc;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  -khtml-box-shadow: 10px 10px 5px #ccc;
}

.viewImg:hover {
  border: 2px solid #fff;
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
  box-shadow: 10px 10px 5px #ccc;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  -khtml-box-shadow: 10px 10px 5px #ccc;
  cursor: pointer;
}

.center-fit {
  /* max-width: 100%;
  max-height: 100vh; */
  margin: auto;
}

.toggleButtonStyle {
  padding: 5px 10px !important;
  height: 35px;
  font-family: "Google-Sans" !important;
  border-color: #e92220 !important;
  color: #e92220 !important;
  text-transform: capitalize !important;
  font-size: clamp(0.7rem, 0.8rem, 1rem) !important;
  margin-top: 10px !important;
}
.selectedToggleButtonStyle {
  background-color: #e92220 !important;
  color: white !important;
  border: none !important;
  padding: 5px 10px !important;
  height: 35px;
  font-family: "Google-Sans" !important;
  text-transform: capitalize !important;
  font-size: clamp(0.7rem, 0.8rem, 1rem) !important;
  margin-top: 10px !important;
}
.selectedToggleButtonStyle svg {
  font-size: 2.7vh;
}

.MuiDataGrid-columnHeadersInner {
  background-color: rgb(246, 167, 5);
}
