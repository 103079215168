.tooltip {
  color: white;
  display: initial;
  padding: 10px;
  border-radius: 3px;
  width: 20%;
  font-size: 12px;
  font-style: italic;
  position: absolute;
  margin: 0 0 0 10px;
  background-color: hsla(0, 0%, 20%, 0.9);
  z-index: 999;
}
.tooltipTick:after {
  position: absolute;
  left: -7px;
  width: 0;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
  transform: rotate(90deg);
  top: 20px;
}

.formLabel {
  display: "block";
  position: "absolute";
  top: "-10px";
  background-color: "white";
  font-size: "11px";
  color: "#313131";
  padding: "0px 5px";
  left: "10px";
}
.formDiv {
  position: "relative";
  min-height: "35px";
}
.checkBoxPlace {
  position: "absolute";
  top: "5px";
}
.MuiCheckbox-root {
  margin-top: 0px !important;
}
.MuiCheckbox-root + .MuiFormControlLabel-label {
  padding-top: 0px;
}

.mRadioBox {
  position: relative;
  min-height: 35px;
  margin-top: 10px;
}
.mRadioBox label {
  font-family: "Google-Sans !important";
}
