.readOnlyField_Text {
  padding-left: 5px;
  padding-right: 5px;
  display: block;
  opacity: 0.6;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.readOnlyField_Header {
  font-size: 14px;
  color: #000;
  display: block;
  font-weight: 500;
  font-family: 'Google-Sans';
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
