.menuItemText {
  padding-left: 35px;
}
.displayOnMouse {
  position: fixed;
  left: 55px;
  background: white;
  box-shadow: 5px 8px 15px #dddddd4a;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: inline-flex;
}
.displayOnMouseOff {
  position: fixed;
  left: 48px;
  background: white;
  box-shadow: 5px 8px 15px #dddddd4a;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: none;
}
.appMenu {
  position: initial;
  width: 100%;
}
.menuItem {
  width: 250px;
}
.sidemenulogo {
  width: 120px;
  margin: auto;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}
.sidemenuIcon {
  width: 45px;
  margin: auto;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}
.sideShowHideIcon {
  position: fixed;
  z-index: 99999;
  bottom: 5px;
  padding: 5px 20px;
  width: 60px;
  text-align: center;
}
.HideIconLeft {
  left: -5px;
}
.ShowIconLeft {
  left: 210px;
}
.MuiTypography-root {
  font-family: 'Google-sans' !important;
}
