input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.MuiInputLabel-asterisk {
  color: red !important;
}

.fullScreenTextArea {
  height: 100%;
}
.fullScreenTextArea textarea {
  line-height: 157%;
  box-sizing: border-box;
  border: none;
  margin: 0;
  width: 100%;
  resize: none !important;
  height: 100% !important;
}
