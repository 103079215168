div[class*="Component-horizontalScrollContainer"],
.dataTable.boxShadowNoneTable div[class*="jss"] {
  position: initial !important;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginTopCenter {
  z-index: 99999;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
.display-linebreak {
  white-space: pre-line;
}

.MuiDialog-root {
  z-index: 9999 !important;
}
.pt10 {
  padding-top: 10px !important;
}
.iconBox {
  width: 10%;
  border: 1px solid #d0d0d0;
  padding: 10px 5px;
  text-align: center;
  display: inline-block !important;
}
.whiteColorSpan span {
  color: white;
}
.minHeightWrapper {
  min-height: calc(100vh - 190px) !important;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background: #fff !important;
}
.list {
  padding: 10px;
}
.row {
  border-bottom: 1px solid #ebeced;
  text-align: left;
  margin: 5px 0;
  display: flex;
  align-items: center;
}
.image {
  margin-right: 10px;
}
.content {
  padding: 10px;
}
.table-cell {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  line-height: 30px;
  text-align: left;
  padding: 0 4px;
}
.MashTableCell-root {
  color: inherit;
  outline: 0;
  vertical-align: middle;
  font-size: 12px;
  font-family: "Google-Sans";
  font-weight: 500;
}
.MashTableCell-body {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
}
/* .BodyGrid {
  width: 100%;
}
.HeaderGrid {
  width: 100%;
  overflow: hidden !important;
} */
.evenRow {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.oddRow {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.oddRow {
  background-color: #fff;
}
.cell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em 1em;
}
.cell {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.columnArrowLabel {
  vertical-align: sub;
  font-family: "Google-Sans-Bold";
}
.columnArrowSize {
  font-size: 15px;
  margin-left: 5px;
  vertical-align: text-top;
}
.exportButton {
  width: 16px;
  margin-top: -6px;
}
.tooltip {
  color: white;
  display: initial;
  padding: 10px;
  border-radius: 3px;
  width: 20%;
  font-size: 12px;
  font-style: italic;
  position: absolute;
  margin: 0 0 0 10px;
  background-color: hsla(0, 0%, 20%, 0.9);
  z-index: 999;
}
.tooltipTick:after {
  position: absolute;
  left: -7px;
  width: 0;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
  transform: rotate(90deg);
  top: 20px;
}
.tableTitle {
  color: #a9212d;
  font-size: 1.25rem;
  font-family: "Google-Sans";
  font-weight: 500;
  line-height: 1.6;
  vertical-align: "text-top";
}
.tableLoadingDiv {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background: #ffffffa8;
  text-align: center;
}
.Toastify__toast-body {
  white-space: pre-line;
}
.cornorRound {
  border-radius: 8px;
}
.skeletonCard {
  width: 100%;
  margin: auto;
}
.noRecordFoundMainDiv {
  position: absolute;
  top: 0px;
  width: 100%;
}
.noRecordFoundChilDiv {
  width: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: fit-content;
  text-align: center;
}
::-webkit-scrollbar-thumb {
  border: none !important;
  border-radius: 10px !important;
  background: #babaca !important;
}
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px;
}
.pagiBox {
  position: absolute !important;
  bottom: 0;
  z-index: 9;
  height: 36px;
}
.ReactVirtualized__Grid {
  outline: none;
}
.paginationUl {
  padding: 0 !important;
  display: flex !important;
}
.UnSelectedPagination {
  border: none;
  font-size: 1.8vh;
  margin: 0px 5px;
  border-radius: 5px;
  text-transform: capitalize;
  padding: 5px 12px;
  background: white;
}
.selectedPagination {
  background: #a9212d;
  color: white;
  border-radius: 5px;
  font-family: "Google-Sans-Bold";
  border: none;
  font-size: 1.5vh;
  margin: 0px 5px;
  border-radius: 5px;
  padding: 5px 12px;
}
.paginationNav {
  display: inline-block;
}

.container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 9px !important;
  height: 19px;
  width: 19px;
  background-color: #fff;
  border: 2px #777 solid;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #a9212d;
  border: 2px #a9212d solid;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}
.selectCheck {
  margin-bottom: 8px;
  align-self: center;
}

.ReactVirtualized__Grid__innerScrollContainer:has(.tableHeaderCustom) {
  background-color: rgb(234, 234, 234);
}
