.headerDesign {
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 10px 20px;
  box-shadow: 14px 2px 10px #e8e8e8;
  float: left;
  position: sticky;
  top: 0px;
  width: 100% !important;
  right: 0px;
  z-index: 10;
}
.menuBottom0 ul {
  padding-bottom: 0;
}
.menuBottom0 {
  padding: 0px !important;
}
.menuBottom0 .MuiList-padding {
  padding: 0px !important;
}
.menuBottom0 .MuiListItem-root {
  padding: 10px 10px;
}
.menuBottom0 .MuiTypography-root {
  font-size: 16px;
}
.loggedInSystemBox {
  margin-right: 15px;
  display: flex;
  margin-top: 0px;
  border: 1px #e92220;
  padding: 5px 15px;
  border-radius: 50px;
  border-style: dashed;
  padding-right: 10px;
  fill: #e92220;
  color: #e92220;
  cursor: pointer;
}
.loggedInSystemBox:hover {
  background-color: #e92220;
  fill: #fff;
  color: #fff;
}
.viewMyAccessText {
  font-size: clamp(1rem, 1rem, 2rem);
  height: fit-content;
  margin: auto;
  margin-left: 5px;
}
