input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.react-datepicker-wrapper .MuiInputLabel-asterisk {
  color: red !important;
}

.react-datepicker-wrapper .react-datepicker__day--outside-month {
  color: #bababa !important;
  pointer-events: none;
}
.react-datepicker__day--selected {
  background: #e92220 !important;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #e92220 !important;
}
.react-datepicker__month-select {
  background: white !important;
  padding: 1px !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.react-datepicker__year-select {
  background: white !important;
  padding: 1px !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.react-datepicker-wrapper .MuiFormControl-root .MuiInput-root input {
  width: "100%";
  font-family: "Google-Sans" !important;
}

.react-datepicker-wrapper .MuiFormControl-root .MuiInput-root {
  height: 35px !important;
  margin-top: 16px !important;
}

.react-datepicker-wrapper .MuiFormControl-root .MuiInput-root::before {
  border: none;
}

.react-datepicker-wrapper .MuiFormControl-root .MuiInput-root::after {
  border: none;
}
.react-datepicker-wrapper .MuiFormControl-root .MuiInput-root:hover {
  border: "1px solid rgba(0, 0, 0, 0.23)";
}

.react-datepicker-wrapper
  .MuiFormControl-root
  .MuiInput-root:hover:not(.Mui-disabled):before {
  border: none !important;
}

.react-datepicker-wrapper .MuiFormControl-root label {
  color: #313131 !important;
  font-weight: 500;
  font-family: "Google-Sans" !important;
}
.react-datepicker-wrapper .MuiFormControl-root .Mui-focused {
  color: #313131 !important;
  font-weight: 600;
}
.react-datepicker-wrapper .MuiFormControl-root .Mui-error {
  color: #b00020 !important;
  font-weight: 600;
  margin-top: 1px;
}

.react-datepicker-popper {
  margin-top: 14px;
}

.react-datepicker-wrapper .MuiFormHelperText-root.Mui-error {
  color: #b00020;
  font-weight: 600;
  font-family: "Google-Sans-Bold" !important;
  margin: 0;
}
.react-datepicker-wrapper .MuiFormHelperText-root {
  font-family: "Google-Sans" !important;
}

.react-datepicker__close-icon::after {
  display: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  color: #fff;
  border-radius: 50%;
  height: 70px;
  width: 65px;
  padding: 2px;
  font-size: 25px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker-wrapper:hover .react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  color: #666;
  border-radius: 50%;
  height: 70px;
  width: 65px;
  padding: 2px;
  font-size: 25px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__close-icon:hover:after {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  color: #999;
  border-radius: 50%;
  height: 70px;
  width: 65px;
  padding: 2px;
  font-size: 25px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
